import { useCallback } from 'react';

import { PAGES } from '../common/pages';
import { useConfigContext } from '../contexts/ConfigContext';
import { useQueryParam } from './useQueryParam';

export function useRedirectToKeycloakLogout() {
  const {
    keycloak: {
      paths,
      urls: { external: keycloakUrl },
    },
  } = useConfigContext();
  const [redirectUriParam] = useQueryParam('redirectUri');
  const redirectUriParamValue = redirectUriParam?.value;

  return useCallback(
    (idToken: string, options?: { redirectUri?: string }) => {
      const redirectUri = options?.redirectUri ?? redirectUriParamValue;

      const searchParams = new URLSearchParams({
        id_token_hint: idToken,
        post_logout_redirect_uri: `${window.origin}${PAGES.logoutSuccess.path}${redirectUri ? `?redirectUri=${redirectUri}` : ''}`,
      });

      window.location.href = `${keycloakUrl}/${paths.logout}?${searchParams}`;
    },
    [keycloakUrl, paths.logout, redirectUriParamValue],
  );
}
