import { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';

import { HEADER_NAMES } from '@sbiz/business';
import { ApiError } from '@sbiz/util-browser';
import { isJwtExpired } from '@sbiz/util-jwt';

import { useAuthUserStorageItem } from '../../../hooks/useAuthUserStorageItem';
import { useGetAccessToken } from '../../../hooks/useGetAccessToken';
import { useLogout } from '../../../hooks/useLogout';
import { AuthUser } from '../../session';
import { PREVIEW_ROW_COUNT } from '../constants';
import { API_RESOURCES, ApiRecord, ResourceType } from '../resources';
import {
  ApiRequestAliasOptions,
  ApiRequestOptions,
  CSV_DELIMITERS,
  CsvDelimiter,
  CsvFileUploadFormData,
  CsvPreview,
  CsvPreviewApiResponse,
  CsvUploadResponse,
  QueryValues,
  SafeApiResponse,
} from '../types';
import { getFullPath, getSanitizedFormData, httpRequest } from '../utils';
import { useApiConfig } from './useApiConfig';
import { useGetCaptchaToken } from './useGetCaptchaToken';
import { useSafeApiFetcher } from './useSafeApiFetcher';

const PREVIEW_CACHE = new Map<File, Map<string, CsvPreviewApiResponse>>();

export function useApi<const T extends ResourceType, TDefaultData = ApiRecord<T>>(resourceType: T) {
  const basePath = API_RESOURCES[resourceType].path;

  const { getBaseURL } = useApiConfig();
  const getAccessToken = useGetAccessToken();
  const getCaptchaToken = useGetCaptchaToken();
  const logout = useLogout();
  const safeApiFetcher = useSafeApiFetcher();
  const { getValue: getAuthUser } = useAuthUserStorageItem();

  const getAuthorizationHeader = useCallback(
    (options: { accessToken?: string } & ApiRequestOptions) => {
      const { accessToken } = options;

      let accessTokenError: 'Expired' | 'Missing' | undefined;
      if (accessToken) {
        if (isJwtExpired(accessToken)) {
          accessTokenError = 'Expired';
        }
      } else {
        accessTokenError = 'Missing';
      }

      if (accessTokenError) {
        const method = options.method ?? 'GET';
        const path = `${basePath}/${options.path}`;
        throw new ApiError(`${accessTokenError} access token for authenticated route ${method} ${path}`, {
          status: 401,
        });
      }

      return `Bearer ${accessToken}`;
    },
    [basePath],
  );

  const getHeaders = useCallback(
    async (authUser: AuthUser | null | undefined, options: ApiRequestOptions) => {
      const headers: AxiosRequestConfig['headers'] = { ...options.headers };

      if (!options?.isPublic) {
        const accessToken = await getAccessToken();
        headers.Authorization = getAuthorizationHeader({
          ...options,
          accessToken: accessToken ?? authUser?.accessToken,
        });
      }

      if (authUser?.targetCompanyId) {
        headers[HEADER_NAMES.contextCompany] = authUser?.targetCompanyId;
      }

      const captchaAction = options.captcha?.action;
      if (captchaAction) {
        headers[HEADER_NAMES.captchaToken] = await getCaptchaToken(captchaAction);
      }

      return headers;
    },
    [getAccessToken, getAuthorizationHeader, getCaptchaToken],
  );

  const request = useCallback(
    async <TData>(options: ApiRequestOptions): Promise<SafeApiResponse<TData>> => {
      const { fetcher: fetcherOptions, isAutoLogoutDisabled, ...config } = options;
      const authUser = getAuthUser();

      const response = await safeApiFetcher(async () => {
        const { data, method, params, path } = config;
        const headers = await getHeaders(authUser, config);

        return httpRequest<TData>({
          baseURL: getBaseURL(),
          data,
          headers,
          method,
          params,
          url: getFullPath(basePath, path),
        });
      }, fetcherOptions);

      if (response.error?.status === 401 && !options?.isAutoLogoutDisabled) {
        await logout();
        return { data: null as TData };
      }

      return response;
    },
    [basePath, getAuthUser, getBaseURL, getHeaders, logout, safeApiFetcher],
  );

  const get = useCallback(
    <TData = TDefaultData>(path: string, options?: ApiRequestAliasOptions) =>
      request<TData>({ ...options, method: 'GET', path }),
    [request],
  );

  const deleteRequest = useCallback(
    <TData>(path: string, options: ApiRequestAliasOptions) => request<TData>({ ...options, method: 'DELETE', path }),
    [request],
  );

  const patch = useCallback(
    <TData = Partial<TDefaultData>>(path: string, options?: ApiRequestAliasOptions) =>
      request<TData>({ ...options, method: 'PATCH', path }),
    [request],
  );

  const post = useCallback(
    <TData = Partial<TDefaultData>>(path: string, options?: ApiRequestAliasOptions) =>
      request<TData>({ ...options, method: 'POST', path }),
    [request],
  );

  const create = useCallback(
    (
      values: { [P in keyof TDefaultData]?: TDefaultData[P] | string | string[] | null },
      options?: Pick<ApiRequestOptions, 'fetcher' | 'path'>,
    ) =>
      post<TDefaultData>(options?.path ?? '', {
        data: getSanitizedFormData(values, { isNullValueRemoved: true }),
        fetcher: options?.fetcher,
      }),
    [post],
  );

  const deleteMany = useCallback(
    async (ids: string[], options?: Pick<ApiRequestOptions, 'fetcher'>) =>
      deleteRequest<boolean>('', { data: ids, fetcher: options?.fetcher }),
    [deleteRequest],
  );

  const updateOne = useCallback(
    <const TValues extends QueryValues<TDefaultData>>(
      id: string,
      values: TValues,
      options?: Pick<ApiRequestOptions, 'fetcher'>,
    ) => patch<TDefaultData>(id, { data: getSanitizedFormData(values), fetcher: options?.fetcher }),
    [patch],
  );

  const csvPreviewFetcher = useCallback(
    (path: string, file: File, delimiter: string) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('delimiter', delimiter);
      formData.append('limit', String(PREVIEW_ROW_COUNT + 1));

      return post<CsvPreviewApiResponse>(path, { data: formData });
    },
    [post],
  );

  const previewCsvFile = useCallback(
    async (path: string, file: File, delimiterParam?: string): Promise<SafeApiResponse<CsvPreview>> => {
      if (!PREVIEW_CACHE.has(file)) {
        PREVIEW_CACHE.set(file, new Map());
      }

      const delimiters = delimiterParam ? [delimiterParam as CsvDelimiter] : CSV_DELIMITERS;

      let apiError: ApiError | null = null;
      for (const delimiter of delimiters) {
        if (PREVIEW_CACHE.get(file)?.has(delimiter)) {
          const data = PREVIEW_CACHE.get(file)?.get(delimiter) as CsvPreviewApiResponse;
          return { data: { ...data, delimiter } };
        } else {
          const { data, error } = await csvPreviewFetcher(path, file, delimiter);
          if (data) {
            PREVIEW_CACHE.get(file)?.set(delimiter, data);
            return { data: { ...data, delimiter } };
          } else {
            apiError = error;
          }
        }
      }

      return { error: apiError ?? new ApiError('Invalid csv file') };
    },
    [csvPreviewFetcher],
  );

  const uploadCsvFile = useCallback(
    async <TFormData extends object>(path: string, file: File, data: CsvFileUploadFormData<TFormData>) => {
      const formData = new FormData();
      formData.append('file', file);
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
      }

      return post<CsvUploadResponse>(path, { data: formData });
    },
    [post],
  );

  return {
    basePath,
    create,
    deleteMany,
    deleteRequest,
    get,
    patch,
    post,
    previewCsvFile,
    updateOne,
    uploadCsvFile,
  } as const;
}
