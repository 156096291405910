import { useCallback } from 'react';

import { PAGES } from '../common/pages';
import { useQueryParam } from './useQueryParam';
import { useRoute } from './useRoute';

export function useRedirectAfterLogout() {
  const [redirectUriParam] = useQueryParam('redirectUri');
  const redirectUriParamValue = redirectUriParam?.value;
  const [, setRoute] = useRoute();

  return useCallback(
    (options?: { redirectUri?: string }) => {
      const redirectUri = options?.redirectUri ?? redirectUriParamValue;

      if (redirectUri) {
        window.location.href = redirectUri;
      } else {
        setRoute({ pathname: PAGES.login.path, searchParams: null });
      }
    },
    [redirectUriParamValue, setRoute],
  );
}
