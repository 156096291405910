'use client';

import { Cache } from 'swr';

import { getStorage, getStorageItem, removeStorageItem, setStorageItem, StorageType } from '@sbiz/util-browser';

import { API_CACHE_STORAGE_KEY_PREFIX } from './common';

export function apiCacheProvider() {
  const inMemoryCache = new Map([...getStorageEntries('local'), ...getStorageEntries('session')]);

  const cache = {
    get: inMemoryCache.get.bind(inMemoryCache),
    keys: inMemoryCache.keys.bind(inMemoryCache),
  } as Cache<unknown>;

  cache.delete = (key) => {
    inMemoryCache.delete(key);

    const storageType = getStorageType(key);
    if (storageType) {
      removeStorageItem(key, storageType);
    }
  };

  cache.set = (key, value) => {
    inMemoryCache.set(key, value);

    const storageParams = getStorageParams(key);
    if (storageParams) {
      const { expiresIn, type } = storageParams;
      const expiresAt = expiresIn < Infinity ? Date.now() + expiresIn : undefined;
      setStorageItem(key, { ...value, expiresAt }, type);
    }
  };

  return cache;
}

function getStorageEntries(storageType: StorageType) {
  try {
    return Object.keys(getStorage(storageType))
      .filter((key) => isStorageKey(key))
      .map((key) => [key, getStorageItem(key, storageType)] as const);
  } catch {
    return [];
  }
}

function getStorageParams(key: string) {
  if (isStorageKey(key)) {
    const [, storageType, expiresIn] = key.split(':');
    return { expiresIn: Number(expiresIn), type: storageType as StorageType } as const;
  }
}

function getStorageType(key: string) {
  const storageParams = getStorageParams(key);
  return storageParams?.type;
}

function isStorageKey(key: string) {
  return key.startsWith(`${API_CACHE_STORAGE_KEY_PREFIX}:`);
}
