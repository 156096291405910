import { isAxiosError } from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiError, ApiErrorOptions, isApiError, UNKNOWN_API_ERROR_KEY } from '@sbiz/util-browser';
import { isPlainObject } from '@sbiz/util-common';

export function useGetApiError() {
  const { t } = useTranslation();

  return useCallback(
    (error: unknown): ApiError => {
      if (isApiError(error)) {
        return error;
      }

      const options: ApiErrorOptions = {};

      if (isAxiosError(error)) {
        const { response } = error;

        if (response) {
          const { data, status } = response;
          const { code, ...details } = data;

          options.code = code;
          options.status = status;

          if (Object.keys(details).length) {
            options.details = details;
          }
        } else {
          ({ code: options.code, status: options.status } = error);
          options.messageKeys = ['api.error.notReachable'];
        }
      } else if (isPlainObject(error)) {
        if ('code' in error && typeof error.code === 'string') {
          options.code = error.code;
        }

        if ('details' in error && isPlainObject(error.details)) {
          options.details = error.details;
        }

        if ('status' in error && typeof error.status === 'number') {
          options.status = error.status;
        }
      }

      if (options.code && !options.messageKeys) {
        options.messageKeys = [`api.error.code.${options.code}`];

        if (options.status) {
          options.messageKeys.push(`api.error.status.${options.status}`);
        }
      }

      const apiError = new ApiError(t(options.messageKeys ?? [UNKNOWN_API_ERROR_KEY]), options);
      return apiError;
    },
    [t],
  );
}
