'use client';

import { ReactNode } from 'react';
import { SWRConfig, SWRConfiguration } from 'swr';

import { apiCacheProvider } from '../../../api-cache';

const SWR_CONFIG = { provider: apiCacheProvider, revalidateOnFocus: false } as const satisfies SWRConfiguration;

export function SWRProvider({ children }: { children: ReactNode }) {
  return <SWRConfig value={SWR_CONFIG}>{children}</SWRConfig>;
}
