import { ArrowBackIosNew } from '@mui/icons-material';

import {
  AccountingIcon,
  AdminIcon,
  BenefitIcon,
  CheckIcon,
  CompanyIcon,
  EmployeeIcon,
  GiftCardIcon,
  ManagerIcon,
  OrderIcon,
  ProfileIcon,
} from '../../components/icons';
import {
  ACCOUNTING_PAGE_NAME,
  ADMIN_PAGE_NAME,
  BENEFITS_PAGE_NAME,
  CHECKS_PAGE_NAME,
  COMPANY_PAGE_NAME,
  COMPANY_SELECTION_PAGE_NAME,
  EMPLOYEES_PAGE_NAME,
  GIFT_CARDS_PAGE_NAME,
  MANAGERS_PAGE_NAME,
  ORDERS_PAGE_NAME,
  PageLink,
  PAGES,
  PROFILES_PAGE_NAME,
} from './types';

export const COMPANY_SELECTION_LINK = {
  icon: <ArrowBackIosNew />,
  page: COMPANY_SELECTION_PAGE_NAME,
} as const satisfies PageLink;

export const ADMIN_LINK = { icon: <AdminIcon />, page: ADMIN_PAGE_NAME } as const satisfies PageLink;

export const COMPANY_INDEPENDANT_PATHS: string[] = [
  PAGES.admin.path,
  PAGES.companySelection.path,
  PAGES.permissionsSets.path,
  PAGES.settings.path,
];

export const PAGE_LINKS = [
  COMPANY_SELECTION_LINK,
  { icon: <CompanyIcon />, page: COMPANY_PAGE_NAME },
  { icon: <ProfileIcon />, page: PROFILES_PAGE_NAME },
  { icon: <ManagerIcon />, page: MANAGERS_PAGE_NAME },
  { icon: <EmployeeIcon />, page: EMPLOYEES_PAGE_NAME },
  { icon: <BenefitIcon />, page: BENEFITS_PAGE_NAME },
  { icon: <CheckIcon />, page: CHECKS_PAGE_NAME },
  { icon: <GiftCardIcon />, page: GIFT_CARDS_PAGE_NAME },
  { icon: <OrderIcon />, page: ORDERS_PAGE_NAME },
  { icon: <AccountingIcon />, page: ACCOUNTING_PAGE_NAME },
  ADMIN_LINK,
] as const satisfies PageLink[];

export const TAB_PARAM_NAME = 'tab';
export const TABLE_CONFIG_PARAM_NAME = 'tableConfig';
