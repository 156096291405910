import { useCallback } from 'react';

import { useDeleteSession } from './useDeleteSession';
import { useGetIdToken } from './useGetIdToken';
import { useRedirectAfterLogout } from './useRedirectAfterLogout';
import { useRedirectToKeycloakLogout } from './useRedirectToKeycloakLogout';

export function useLogout() {
  const deleteSession = useDeleteSession();
  const getIdToken = useGetIdToken();
  const redirectAfterLogout = useRedirectAfterLogout();
  const redirectToKeycloakLogout = useRedirectToKeycloakLogout();

  return useCallback(
    async (options?: { redirectUri?: string }) => {
      const idToken = await getIdToken();

      if (idToken) {
        redirectToKeycloakLogout(idToken, options);
      } else {
        await deleteSession();
        redirectAfterLogout(options);
      }
    },
    [deleteSession, getIdToken, redirectToKeycloakLogout, redirectAfterLogout],
  );
}
